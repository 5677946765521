<template>
  <div
    :class="{ 'bulk-edit': bulkEdit }"
    class="ReportData"
  >
    <div class="data-actions">
      <div class="bulk-edit-actions">
        <el-badge
          v-if="selectedTasks.length"
          :value="selectedTasks.length"
          type="warning"
        >
          <el-button
            icon="el-icon-edit"
            size="mini"
            plain
            @click="bulkEditWithDialog"
          />
        </el-badge>
        <el-badge
          v-if="selectedTasks.length"
          :value="selectedTasks.length"
        >
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            plain
            @click="bulkDeleteWithConfirmation"
          />
        </el-badge>
        <el-tooltip
          content="Toggle multiple task editing mode"
          class="bulk-toggle-tooltip"
          placement="top"
        >
          <el-button
            type="info"
            icon="el-icon-edit"
            size="mini"
            plain
            @click="toggleBulkEdit"
          >
            Bulk Edit
          </el-button>
        </el-tooltip>
      </div>
      <el-tooltip
        content="Expand or collapse rows with time entries"
        placement="top"
      >
        <el-button
          size="mini"
          class="data-actions-expand"
          @click="expandRows = !expandRows"
        >
          <span v-if="expandRows">Collapse All</span>
          <span v-else>Expand All</span>
        </el-button>
      </el-tooltip>
      <el-tooltip
        content="Refresh Report Data"
        placement="top"
      >
        <el-button
          icon="el-icon-refresh"
          size="mini"
          circle
          @click="$emit('refresh')"
        />
      </el-tooltip>
    </div>
    <el-table
      :data="entries"
      :row-class-name="tableRowClassName"
      :default-sort="{ prop: 'date', order: 'descending' }"
      :expand-row-keys="expandRowKeys"
      row-key="uuid"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        :class-name="`column-expand ${bulkEdit ? 'is--hidden' : ''}`"
        type="expand"
        prop="timeEntries"
        width="15"
        label=""
      >
        <template slot-scope="scope">
          <div
            v-for="item in scope.row.timeEntries"
            :key="item.uuid"
          >
            {{ item.formattedRange }}
            <span class="text-grey">{{ item.formattedDate }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :class-name="`column-selection ${bulkEdit ? '' : 'is--hidden'}`"
        type="selection"
        prop="timeEntries"
        width="25"
        label=""
      />

      <el-table-column
        prop="description"
        label="Description"
        class-name="column-description"
        sortable
      >
        <template slot-scope="scope">
          <div
            :title="scope.row.description"
            class="task-description"
          >
            {{ scope.row.description }}
          </div>
          <div
            v-if="scope.row.tags.length"
            :title="scope.row.tags.map(t => t.name).join(', ')"
            class="tag-list"
          >
            <el-tag
              v-for="tag in scope.row.tags"
              :key="tag.uuid"
              type="success"
              size="mini"
            >
              {{ tag.name }}
            </el-tag>
            <div class="border-gradient" />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="project"
        label="Project"
        width="110"
        class-name="column-project"
        sortable
      />
      <el-table-column
        prop="user"
        label="User"
        width="110"
        class-name="column-user"
        sortable
      />
      <el-table-column
        :formatter="formatEta"
        prop="eta"
        label="Task ETA"
        width="140"
        class-name="column-eta"
        sortable
      />
      <el-table-column
        :formatter="formatDuration"
        prop="duration"
        label="Duration"
        width="140"
        class-name="column-duration"
        sortable
      />
      <el-table-column
        :formatter="formatAmountCell"
        prop="billedAmount"
        label="Billed Amount"
        class-name="column-billed-amount"
        width="133"
        sortable
      />
      <el-table-column
        prop="time"
        label="Time"
        width="147"
        sortable
      >
        <template slot-scope="scope">
          <div>{{ scope.row.time.timeRange }}</div>
          <div class="text-grey">
            {{ scope.row.time.date }}
          </div>
        </template>
      </el-table-column>
      <template
        v-if="!!entries.length"
        slot="append"
      >
        <el-table
          :data="summaryEntries"
          style="width: 100%"
          :show-header="false"
          row-class-name="row-summary"
        >
          <el-table-column
            prop="label"
            class-name="column-summary-label"
          />
          <el-table-column
            prop="eta"
            width="140"
          />
          <el-table-column
            prop="duration"
            width="140"
          />
          <el-table-column
            prop="billedAmount"
            label=""
            width="280"
            class-name="column-billed-amount"
          >
            <template slot-scope="scope">
              <div
                v-for="amount in scope.row.billedAmounts"
                :key="amount.currency.uuid"
                class="billed-amount-line"
              >
                {{ formatAmount(amount.currency, amount.amount) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table>

    <el-dialog
      :title="`Bulk edit ${selectedTasks.length} tasks`"
      :visible.sync="bulkEditFormVisible"
      width="30%"
    >
      <el-form
        :model="bulk"
        label-width="120px"
        size="mini"
      >
        <el-form-item
          label="Description"
        >
          <el-input
            v-model="bulk.description"
            autocomplete="off"
            clearable
          />
        </el-form-item>
        <el-form-item
          label="Project"
        >
          <el-select
            v-model="bulk.projectUuid"
            placeholder="Please select a new project"
            value-key="uuid"
            style="width: 205px"
            clearable
          >
            <el-option
              v-for="project in projects.all()"
              :key="project.uuid"
              :label="project.name"
              :value="project.uuid"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Tags"
        >
          <el-select
            v-model="bulk.tags"
            placeholder="Please select new tags"
            value-key="uuid"
            style="width: 205px"
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="tag in user.tags"
              :key="tag.uuid"
              :label="tag.name"
              :value="tag.uuid"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Date"
        >
          <el-date-picker
            v-model="bulk.createdAt"
            type="date"
            placeholder="Pick a new date"
            style="width: 200px"
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="mini"
          @click="bulkEditFormVisible = false"
        >
          Cancel
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="bulkChangeApply"
        >
          Apply changes
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { Task } from '@timeragent/core';

export default {
  name: 'ReportData',

  props: {
    entries: {
      type: Array,
      required: false,
      default: () => [],
    },
    summary: {
      type: Object,
      required: false,
      default: () => {
      },
    },
  },

  data: () => ({
    expandRows: false,
    bulkEdit: false,
    bulkEditFormVisible: false,
    selectedTasks: [],
    bulk: new Task(),
  }),

  computed: {
    ...mapGetters([
      'projectRepository',
      'user',
    ]),
    projects() {
      return this.projectRepository.dataset;
    },
    summaryEntries() {
      return [{
        stub: '',
        label: 'Totals:',
        eta: moment.duration(+this.summary.eta, 'ms').format(),
        duration: moment.duration(+this.summary.duration, 'ms').format(),
        billedAmounts: this.summary.billedAmounts,
      }];
    },
    expandRowKeys() {
      return this.expandRows
        ? this.entries
          .map(entry => (entry.timeEntries.length ? entry.uuid : null))
          .filter(entry => entry)
        : [];
    },
  },

  methods: {
    ...mapActions(['deleteTask', 'updateTasks']),

    // Helpers
    toggleBulkEdit() {
      this.bulkEdit = !this.bulkEdit;
      this.selectedTasks = [];
      document.activeElement.blur();
    },
    handleSelectionChange(val) {
      this.selectedTasks = val;
    },
    async bulkDeleteWithConfirmation() {
      try {
        await this.$confirm(`This will permanently delete ${this.selectedTasks.length} tasks. Continue?`, 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning',
        });

        await this.deleteTask({ uuids: this.selectedTasks.map(task => task.uuid) });
        this.$emit('refresh');
        this.$message({
          type: 'success',
          message: 'The tasks deleted.',
        });
      } catch (e) {
        //
      }
    },
    bulkEditWithDialog() {
      this.bulkEditFormVisible = true;
    },
    async bulkChangeApply() {
      await this.updateTasks({ uuids: this.selectedTasks.map(task => task.uuid), task: this.bulk });
      this.bulkEditFormVisible = false;

      this.$emit('refresh');
      this.$message({
        type: 'success',
        message: 'The tasks changed.',
      });
    },
    timeSorter(a, b) {
      console.log(a);

      return a.date > b.date;
    },

    // Decorators
    tableRowClassName({ row }) {
      if (row.timeEntries && !row.timeEntries.length) {
        return 'no-time-entries';
      }

      return '';
    },

    // Formatters
    formatEta(row) {
      return moment.duration(+row.eta, 'ms').format();
    },
    formatDuration(row) {
      return moment.duration(+row.duration, 'ms').format();
    },
    formatAmountCell(row) {
      return this.formatAmount(row.currency, row.billedAmount);
    },
    formatAmount(currency, amount) {
      return `${currency.symbol} ${Math.floor(
        (amount + 0.00001) * (10 ** currency.decimalDigits),
      ) / (10 ** currency.decimalDigits)}`;
    },
  },
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
  .el-table .warning-row {
    background : oldlace;
  }

  .el-table .success-row {
    background : #f0f9eb;
  }

  .el-table::v-deep .el-table__empty-text {
    padding : 150px;
  }

  .el-table::v-deep td.column-description .cell {
    display               : grid;
    grid-template-columns : auto max-content;
    justify-content       : space-between;
  }

  .el-table::v-deep td.column-description .task-description {
    min-width : 100px;
  }

  .el-table::v-deep td.column-description .task-description,
  .el-table::v-deep td.column-project .cell,
  .el-table::v-deep td.column-user .cell,
  .el-table::v-deep td.column-eta .cell {
    text-overflow : ellipsis;
    overflow      : hidden;
    white-space   : nowrap;
    word-break    : keep-all;
  }

  .el-table::v-deep td.column-description .el-tag {
    margin-left : 2px;
  }

  .el-table::v-deep td.column-billed-amount {
    color       : #67c23b;
    font-weight : bold;
  }

  .el-table::v-deep td.column-summary-label {
    padding-left : 15px;
  }

  .text-grey {
    color : #909399;
  }

  .el-table::v-deep .row-summary {
    font-weight : bold;
    color       : #909399;
  }

  .border-gradient {
    position   : absolute;
    right      : 0;
    height     : 70px;
    width      : 25px;
    top        : 0;
    background : linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }

  .border-gradient:before {
    content    : '';
    position   : absolute;
    right      : 0;
    height     : 70px;
    width      : 25px;
    top        : 0;
    opacity    : 0;
    background : linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #F5F7FA 100%);
    transition : opacity 0.25s ease;
  }

  .el-table::v-deep tr:hover .border-gradient:before {
    opacity : 1
  }

  .el-table::v-deep .is--hidden {
    display : none;
  }

  .el-table::v-deep colgroup col:nth-child(1),
  .el-table::v-deep colgroup col:nth-child(1) {
    display : table-column;
  }

  .el-table::v-deep colgroup col:nth-child(2),
  .el-table::v-deep colgroup col:nth-child(2) {
    display : none;
  }

  .bulk-edit .el-table::v-deep colgroup col:nth-child(1),
  .bulk-edit .el-table::v-deep colgroup col:nth-child(1) {
    display : none;
  }

  .bulk-edit .el-table::v-deep colgroup col:nth-child(2),
  .bulk-edit .el-table::v-deep colgroup col:nth-child(2) {
    display : table-column;
  }

  .el-table::v-deep .el-table__append-wrapper colgroup col,
  .el-table::v-deep .el-table__append-wrapper colgroup col {
    display : table-column !important;
  }

  .el-table::v-deep .no-time-entries .el-table__expand-column .cell,
  .el-table::v-deep .no-time-entries .column-expand .cell {
    display : none !important;
  }

  .data-actions {
    display               : grid;
    grid-template-columns : auto min-content min-content;
    border-bottom         : 1px solid #ebeef5;
    padding-bottom        : 10px;
  }

  .data-actions-expand {
    align-self : end;
  }

  .bulk-toggle-tooltip {
    width : min-content;
  }

  .bulk-edit-actions {
    display               : grid;
    grid-template-columns : min-content min-content min-content;
    grid-gap              : 20px;
  }
</style>
